import React from "react";
import "./ErrorBoundary.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate that an error has occurred
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI when an error occurs
      return (
        <div className="error-boundary">
          <h1>Something went wrong.</h1>
          <p>
            Try refreshing the page or contact support if the problem persists.
          </p>

          <button onClick={() => window.location.reload()}>Refresh</button>
        </div>
      );
    }

    // Render children components if no error occurred
    return this.props.children;
  }
}

export default ErrorBoundary;
