import React, { Fragment, useCallback, useEffect, useState } from "react";
import "./index.scss";
import CustomModal from "../CustomModal/CustomModal";
import Noc from "../../../assets/images/modal/noc.svg";
import { useDispatch, useSelector } from "react-redux";
import { Info } from "react-feather";
import { nocModalDocumentsObj } from "../../../Constants/Constants";
import CustomButton from "../../Button/CustomButton/CustomButton";
import UploadButton from "../../Button/UploadButton/UploadButton";
import useAxiosPrivate from "../../../axios/services";
import { UPLOAD_NOC_DOCUMENTS } from "../../../axios/apiendPoint";
import { toast } from "react-hot-toast";
import { fetchdashboardModalStates } from "../../../Pages/Dashboard/ModalSection/modalSectionStore/store";
import VerifiedModal from "../VerifiedModal/VerifiedModal";

const NocModal = (props) => {
  const { isOpen, toggle } = props || {};

  const dispatch = useDispatch();
  const { axiosGet, axiosFormDataPost } = useAxiosPrivate();

  const [fileData, setFileData] = useState(null);
  const [currentNoc, setCurrentNoc] = useState(null);
  const [submitFlag, setSubmitFlag] = useState(false);

  const modalDetails = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data;

  const {
    documentRequiredForUpload = [],
    header,
    subHeader,
  } = modalDetails || {};

  const handleToSetEventName = (name) => setCurrentNoc(name);

  // Function for dynamic modal header
  const renderNocHeader = () => {
    return (
      <>
        <p className="modal_header m-0 p-0 mt-3">{header}!</p>
        {subHeader && <p className="noc_description">{subHeader}</p>}
      </>
    );
  };

  //  Render documents for upload
  const renderNocUploads = () => {
    return (
      <div>
        {documentRequiredForUpload?.map((item, index) => (
          <div
            className="d-flex align-items-center justify-content-between m-32"
            key={index}
          >
            {/* <div className="">
              <p className="noc_type m-0">{nocModalDocumentsObj[item.documentType]}</p>
              {item.documentDeclineReason && <p className="noc_type_error m-0 p-0">{item.documentDeclineReason}</p>}
            </div> */}
            <div className="">
              <p className="noc_type m-0">
                {item.name
                  ? ` ${item.documentType} | ${item.name}`
                  : item.documentType}
              </p>
              {item.documentDeclineReason && (
                <p className="noc_type_error m-0 p-0">
                  {item.documentDeclineReason}
                </p>
              )}
            </div>
            <UploadButton
              handleSubmit={(file) => handleSubmit(file)}
              setFileData={setFileData}
              extrHandleClick={() => handleToSetEventName(item.documentType)}
            />
          </div>
        ))}
      </div>
    );
  };

  // Function to close NOC Modal
  const handleToCloseNocModal = () => {
    submitFlag(false);
  };

  // Function to hit api
  const handleSubmit = async (fileToUpload) => {
    if (fileToUpload && currentNoc) {
      const formData = new FormData();
      formData.append("documentType", currentNoc);
      formData.append("file", fileToUpload);

      const response = await axiosFormDataPost(UPLOAD_NOC_DOCUMENTS, formData);
      if (response?.success) {
        toast.success(response?.msg);
        setFileData(null);
        setCurrentNoc(null);
        dispatch(fetchdashboardModalStates({ axiosGet }));
      } else {
        setFileData(null);
        setCurrentNoc(null);
        dispatch(fetchdashboardModalStates({ axiosGet }));
      }
    } else {
      toast.error("Please upload the document");
    }
  };

  // Function to handle submit Noc modal
  const handleToSubmitNoc = () => {
    setSubmitFlag(true);
    setFileData(null);
    setCurrentNoc(null);
    dispatch(fetchdashboardModalStates({ axiosGet }));
  };

  // useEffect(() => {
  //   // handleSubmit();
  // }, [fileData, currentNoc, handleSubmit]);

  return (
    <div className="noc_modal_container">
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        width="571px"
        modalbody={
          submitFlag ? (
            <VerifiedModal
              closeBtn
              handleClick={handleToCloseNocModal}
              verifiedText="Marksheet and NOC’s Submitted Successfully!"
              verifiedDescription="We will verify the documents and update you in 2 hours"
            />
          ) : (
            <Fragment>
              <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                <img src={Noc} alt="noc" />
                {/* Render Header a/c to options */}
                {renderNocHeader()}
              </div>

              {/* Render Uploads a/c to options */}
              {renderNocUploads()}

              <div className="noc_info_content mb-3">
                <span className="view_label me-1">
                  <Info size={15} className="me-2" />
                  Click here
                </span>
                to know about how to easily get NOC from Insurers and Point Of
                Sales
              </div>

              {/* <CustomButton alignCenter fullWidth handleClick={handleToSubmitNoc} disabled={documentRequiredForUpload.length ? true : false} /> */}
            </Fragment>
          )
        }
      />
    </div>
  );
};

export default NocModal;
