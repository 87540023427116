import React from "react";
import Verified from "../../../assets/lottie/verified.json";
import CustomButton from "../../Button/CustomButton/CustomButton";
import LottiePlayer from "../../LottiePlayer/LottiePlayer";

const VerifiedModal = ({
  verifiedText,
  verifiedDescription,
  closeBtn,
  handleClick,
}) => {

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="mb-5">
        <LottiePlayer height={126} width={126} animationData={Verified} />
      </div>
      <p className="m-0 p-0 mb-3 verified_text text-center">{verifiedText}</p>
      {verifiedDescription && (
        <div className="m-0 p-0 mb-3 verified_description text-center">
          {verifiedDescription}
        </div>
      )}

      {closeBtn && (
        <CustomButton alignCenter fullWidth handleClick={handleClick} />
      )}
    </div>
  );
};

export default VerifiedModal;
