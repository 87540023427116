import React, { useEffect, useState } from "react";
import "./index.css";
import ThumbsUp from "../../../assets/images/GoToDesktop/ThumbsUp.json";
import { ReactComponent as LogoFull } from "../../../assets/images/GoToDesktop/Bimaguide_logo.svg";
import LaptopScreen from "../../../assets/images/GoToDesktop/LaptopScreen.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dashboardUserPageStates } from "../../../Constants/Constants";
import LottiePlayer from "../../LottiePlayer/LottiePlayer";

const GoToDesktop = () => {
  const modalDetails = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data;

  const [showGoToDesktop, setShowGoToDesktop] = useState(false);

  const { name = "", page } = modalDetails || {};

  useEffect(() => {
    if (
      page &&
      ![
        dashboardUserPageStates.MARKSHEET_UPLOAD_PAGE,
        dashboardUserPageStates.AADHAAR_VERIFY_PAGE,
        dashboardUserPageStates.ADDRESS_DETAILS_PAGE,
      ].includes(page)
    ) {
      setShowGoToDesktop(true);
    }
  }, [page]);

  if (!showGoToDesktop) {
    return null;
  }

  return (
    <div className="gtd_container_wrapper">
      <div className="gtd_container">
        <div className="lottie_wrapper">
          <div className="m-3 p-0">
            <LottiePlayer
              animationData={ThumbsUp}
              width="130px"
              height="130px"
            />
          </div>
          <p>Congratulations {name},</p>
          <p className="gtd_p">Thank You for Registering with BimaGuide!</p>
        </div>
        <div className="gtd_second">
          <Link href={process.env.REACT_APP_LANDING_WEBSITE_URL}>
            <LogoFull className="" />
          </Link>
          <img src={LaptopScreen} alt="" />
          <p>
            Our mobile application is coming soon. For now, enjoy seamless
            journey on our desktop site. Stay tuned!
          </p>
        </div>
      </div>
    </div>
  );
};

export default GoToDesktop;
