import React, { useEffect, useState } from "react";
import BookingTable from "./BookingTable";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchBookingData,
  fetchBookingModalData,
  setIsBookingModalOpen,
} from "./Store/store";
import useAxiosPrivate from "../../axios/services";
import {
  setSearchBoxPlaceHolder,
  setSearchBoxValue,
  setShowSearchBox,
} from "../../Components/SearchBox/SearchBoxReducer";
import { newQuoteRequestHeader } from "../../Constants/Constants";
import MultiSelect from "../../Components/MultiSelect/MultiSelect";
import { Calendar } from "react-feather";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import "./index.scss";
import BookingDataModal from "./BookingDataModal";
// import "./index.scss";
const Bookings = () => {
  const { axiosGet2 } = useAxiosPrivate();
  // const searchBoxValue = useSelector((state) => state.searchBoxReducer.seachboxValue);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isLoading,
    bookingData,
    isLoadingModalData,
    bookingModalData,
    isBookingModalOpen,
  } = useSelector((state) => state.bookingReducer);
  const searchBoxValue = useSelector(
    (state) => state.searchBoxReducer.seachboxValue,
  );
  const filterOptions = [
    { label: "Rejected", value: "REJECTED" },
    { label: "Booked", value: "BOOKED" },
  ];

  const [filteredData, setFilteredData] = useState([]);
  const [sortBy, setSortBy] = useState("desc");
  const [filterBy, setFilterBy] = useState("");
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  // const filterProducts ??
  const filterProductOptions = newQuoteRequestHeader.map((item) => ({
    label: item.name,
    value: item.key,
  }));
  const images = newQuoteRequestHeader.map((item) => ({
    label: item.key,
    value: item.image,
  }));
  const [filterProduct, setFilterProduct] = useState("");

  const handleBookingModal = (bookingId) => {
    dispatch(
      fetchBookingModalData({
        axiosGet: axiosGet2,
        params: `bookingId=${bookingId}`,
      }),
    );
    // setIsModalOpen(true);
  };

  const handleFilterBy = (option) => {
    if (option && option.value) {
      setFilterBy(option.value);
    } else {
      setFilterBy("");
    }
  };
  const handleFilterProduct = (option) => {
    if (option && option.value) {
      setFilterProduct(option.value);
    } else {
      setFilterProduct("");
    }
  };
  const handleDateChange = (date) => {
    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const getBookingDetails = () =>
    dispatch(fetchBookingData({ axiosGet: axiosGet2 }));

  useEffect(() => {
    dispatch(setSearchBoxValue(""));
    dispatch(setSearchBoxPlaceHolder("Search by Ticket ID, Reg no."));
  }, []);

  useEffect(() => {
    getBookingDetails();
  }, []);

  useEffect(() => {
    // on data change set the filtered data
    if (bookingData && bookingData.length > 0) {
      setData(bookingData);
      setFilterBy("");
      setFilterByDate({ startDate: "", endDate: "" });
    }
  }, [bookingData]);
  useEffect(() => {
    let filterData = data;
    if (
      searchBoxValue &&
      searchBoxValue.length > 0 &&
      data &&
      data.length > 0
    ) {
      filterData = data.filter((item) => {
        const { bookingId, registrationNumber } = item;
        const searchValue = searchBoxValue.toLowerCase();
        return (
          bookingId?.toLowerCase().includes(searchValue) ||
          registrationNumber?.toLowerCase().includes(searchValue)
        );
      });
    }
    if (
      filterBy &&
      filterBy.length > 0 &&
      filterData &&
      filterData.length > 0
    ) {
      filterData = filterData.filter((item) => {
        const { status } = item;
        const filterValue = filterBy.toLowerCase();
        return status?.toLowerCase().includes(filterValue.toLowerCase());
      });
    }
    if (
      filterProduct &&
      filterProduct.length > 0 &&
      filterData &&
      filterData.length > 0
    ) {
      filterData = filterData.filter((item) => {
        const { product } = item;
        const filterValue = filterProduct.toLowerCase();
        return product?.toLowerCase().includes(filterValue.toLowerCase());
      });
    }
    if (
      filterByDate &&
      filterByDate.startDate &&
      filterByDate.endDate &&
      filterData &&
      filterData.length > 0
    ) {
      filterData = filterData.filter((item) => {
        const { bookingCreationTime } = item;
        const startDate = new Date(filterByDate.startDate).setHours(0, 0, 0, 0);
        const endDate = new Date(filterByDate.endDate).setHours(
          23,
          59,
          59,
          999,
        );
        const bookingCreationTimeDate = new Date(bookingCreationTime);
        return (
          bookingCreationTimeDate >= startDate &&
          bookingCreationTimeDate <= endDate
        );
      });
    }

    setFilteredData(filterData);
  }, [searchBoxValue, filterBy, sortBy, data, filterByDate, filterProduct]);

  return (
    <div className="">
      <div className="filters_container">
        <div className="filter_sort_status">
          {/* Sort By dropdown  */}
          {/* <button className="sort_by_btn" onClick={() => setSortBy(sortBy === "asc" ? "desc" : "asc")}>
            Sort By <span className="sort_by_arrow">{sortBy === "asc" ? "Asc": ""}</span>
          </button> */}

          {/* Sort by dropdown */}
          <MultiSelect
            options={filterProductOptions}
            onChange={handleFilterProduct}
            isClearable={true}
            placeholder="Select Product"
            isMulti={false}
            className="sort_by_dropdown"
          />

          <MultiSelect
            options={filterOptions}
            selectedOptions={[]}
            onChange={handleFilterBy}
            isClearable={true}
            placeholder="Status"
            isMulti={false}
            className="filter_by_dropdown"
          />
        </div>

        <div className="date_container">
          <div className="date_picker_container">
            <Flatpickr
              placeholder="Date Range"
              options={{
                dateFormat: "d-m-Y",
                maxDate: new Date(),
                mode: "range",
              }}
              className="flat_picker_date"
              style={{ width: "250px" }}
              value={
                filterByDate.startDate && filterByDate.endDate
                  ? [filterByDate.startDate, filterByDate.endDate]
                  : ""
              }
              onChange={handleDateChange}
            />
            <Calendar color="#0691FC" className="margin__30" />
          </div>
        </div>
      </div>
      {/* Table Section */}
      <div className="cards-container">
        {filteredData.map((item) => (
          <div className="booking-card-wrapper" key={item.bookingId}>
            <BookingTable
              item={item}
              isLoading={isLoading}
              handleClick={handleBookingModal}
            />
          </div>
        ))}
      </div>
      {isBookingModalOpen && (
        <BookingDataModal
          data={bookingModalData}
          isOpen={isBookingModalOpen}
          handleClose={() => dispatch(setIsBookingModalOpen(false))}
          isLoading={isLoadingModalData}
        />
      )}
    </div>
  );
};

export default Bookings;
