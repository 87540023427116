import React, { Fragment } from "react";

const QuoteHeader = ({ header }) => {
  return (
    <Fragment>
      <div className="veichle_details d-flex flex-column align-items-start justify-content-start">
        <p className="veichle_number">{header?.registrationNumber ?? ""}</p>
        <p className="veichle_owner_name">{header?.name ?? ""}</p>
      </div>

      <div className="veichle_extra_details mt-2 d-flex gap-5 align-items-center justify-content-start">
        <p className="detail">Ticket ID - {header?.ticketId ?? ""}</p>
        {/* <p className="detail">RM - Ashok Singh - 928382929</p> */}
        {/* <p className="detail">Assigned to - Rahul Mishra (ops)</p> */}
      </div>
    </Fragment>
  );
};

export default QuoteHeader;
