import React from "react";
import { Controller } from "react-hook-form";
import FormInput from "../../Components/FormInput/FormInput";
import User from "../../assets/images/common/user.svg";
import RegNoIcon from "../../assets/images/common/reg_number.svg";
import DocFields from "./DocFields";
import PolicyIcon from "../../assets/images/common/text_snippet.svg";
import Phone from "../../assets/images/common/phone.svg";
import MultiSelect from "../MultiSelect/MultiSelect";
import { busSubTypeArray } from "../../Constants/Constants";

const OldVeichle = ({ errors, control, activeHeader, resetField }) => {
  return (
    <div className="form_body">
      <div className="row pb_24 gap_16">
        {/* dropdown for subtype and individual/school name if activeHeader = "BUS" */}

        {activeHeader?.key === "BUS" ? (
          <div className="col-12 pb_16">
            <Controller
              control={control}
              name="vehicleOwner"
              render={({ field }) => (
                <FormInput
                  field={field}
                  icon={<img src={User} alt="user" />}
                  placeholder="Individual/School Name"
                />
              )}
            />
            {errors.vehicleOwner && (
              <div className="error_class">{errors.vehicleOwner.message}</div>
            )}
          </div>
        ) : null}
        {activeHeader?.key === "BUS" ? (
          <div className="col-12 pb_16">
            <Controller
              control={control}
              name="vehicleSubClass"
              render={({ field }) => {
                const { onChange, value, ...rest } = field;
                return (
                  <MultiSelect
                    {...rest}
                    isMulti={false}
                    dataTestId="select_subtype"
                    options={busSubTypeArray}
                    placeholder="Select Vehicle Sub Class"
                    isSearchable
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption.value);
                    }}
                    value={busSubTypeArray.find((obj) => obj.value === value)}
                  />
                );
              }}
            />
            {errors.vehicleSubType && (
              <div className="error_class">{errors.vehicleSubType.message}</div>
            )}
          </div>
        ) : null}
        <div className="col-12 mb_16">
          <Controller
            control={control}
            name="registrationNumber"
            render={({ field }) => (
              <FormInput
                field={field}
                placeholder="Registration Number"
                icon={<img src={RegNoIcon} alt="Reg no" />}
              />
            )}
          />
          {errors.registrationNumber && (
            <div className="error_class">
              {errors.registrationNumber.message}
            </div>
          )}
        </div>
        <div className="col-12 mb_16">
          <Controller
            control={control}
            name="policyNumber"
            render={({ field }) => (
              <FormInput
                field={field}
                placeholder="Enter Policy Number *"
                icon={<img src={PolicyIcon} alt="PolicyNo" />}
              />
            )}
          />
          {errors.policyNumber && (
            <div className="error_class">{errors.policyNumber.message}</div>
          )}
        </div>
        <div className="col-12">
          <Controller
            control={control}
            name="contactNumber"
            render={({ field }) => (
              <FormInput
                field={field}
                placeholder="Enter Partner's Contact No. "
                icon={<img src={Phone} alt="PhoneNo" />}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, '');
                }}
                maxLength={10}
              />
            )}
          />
          {errors.contactNumber && (
            <div className="error_class">{errors.contactNumber.message}</div>
          )}
        </div>
      </div>

      <DocFields
        errors={errors}
        control={control}
        activeHeader={activeHeader}
        isNew={false}
      />
    </div>
  );
};

export default OldVeichle;
