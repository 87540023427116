export const navRoutes = {
  defaultPage: "/dashboard",
  dashboardPage: "/dashboard",
  quoteRequestPage: "/quote-requests",
  bookingsPage: "/bookings",
  knowledgeBankPage: "/knowledge-bank",
  ticketsPage: "/tickets",
  mappingRequestPage: "/mapping-requests",
  grid: "/grid",
  gridFavorites: "/grid/favorites",
};
