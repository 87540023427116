import React from "react";
import "./index.scss";
import Navbar from "./Navbar'/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import GoToDesktop from "../Modal/GoToDesktop/GoToDesktop";
import { isMobileDevice } from "../../utils/utils";

const LayoutWrapper = (props) => {
  return (
    <div className="d-flex layout_wrapper" id="layout_wrapper">
      <Sidebar />
      <div className="w-100 layout_body">
        <Navbar />
        <div className="layout_main">{props.children}</div>
      </div>
      {isMobileDevice() && <GoToDesktop />}
    </div>
  );
};

export default LayoutWrapper;
