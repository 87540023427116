import React, { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import FormInput from "../../Components/FormInput/FormInput";
import { PlusCircle } from "react-feather";
import CustomField from "../../Components/CustomFields/CustomFields";
import MultiSelect from "../../Components/MultiSelect/MultiSelect";
import { policyTypeOptions } from "../../Constants/Constants";

export const isVeichleTypeOptions = {
  newVehicle: [policyTypeOptions.COMPREHENSIVE],
  oldVehicle: [
    policyTypeOptions.COMPREHENSIVE,
    policyTypeOptions.THIRD_PARTY,
    policyTypeOptions.SAOD,
  ],
};

const RequestDetails = ({
  errors,
  control,
  veichleType,
  setVeichleType,
  resetField,
  isNewVehicle = false,
}) => {
  const { insurerList, addOnsList } = useSelector(
    (state) => state.newQuoteRequestReducer,
  );
  const insuranceOptions = insurerList?.map((item) => ({
    label: item,
    value: item,
  }));

  const addOnOptions = addOnsList?.map((item) => ({
    label: item,
    value: item,
  }));

  const [showAddOns, setShowAddOns] = useState(false);

  const toggleAddOns = () => {
    setShowAddOns((prevState) => !prevState);
  };

  const handleToResetAddOns = () => {
    setShowAddOns(false);
    // setSelectedAddOns([]);
    resetField("addOns", []);
  };

  const handleVehicleTypeChange = (selectedOption) => {
    setVeichleType(selectedOption);
    setShowAddOns(false);
    // reset selected insurers
    resetField("selectInsurer", []);
    resetField("idv", "");
    resetField("addOns", []);
    resetField("pypExpiryDate", "");
    resetField("ncb", "");
  };

  return (
    <Fragment>
      <div className="divider pb_16">
        <div className="divider-text ">Request Details</div>
      </div>

      <div className="pb_16">
        <CustomField
          value={veichleType}
          setvalue={handleVehicleTypeChange}
          options={
            isNewVehicle
              ? isVeichleTypeOptions.newVehicle
              : isVeichleTypeOptions.oldVehicle
          }
        />
      </div>

      <div className="row">
        <div
          className={
            veichleType[0] === policyTypeOptions.COMPREHENSIVE ||
            veichleType[0] === policyTypeOptions.SAOD
              ? "col-8 pb-0 mb-0"
              : "col-12 pb-0 mb-0"
          }
        >
          <Controller
            control={control}
            name="selectInsurer"
            defaultValue={null}
            render={({ field }) => {
              const { onChange, value, ...rest } = field;
              return (
                <MultiSelect
                  {...rest}
                  dataTestId="select_insurer"
                  options={insuranceOptions}
                  isSearchable
                  onChange={(value) => {
                    if (value.length <= 3) {
                      field.onChange(value);
                    }
                  }}
                  value={insuranceOptions?.filter((option) =>
                    value?.find((item) => item.label === option.label),
                  )}
                  isClearable
                  placeholder="Select Insurers"
                />
              );
            }}
          />
          {errors.selectInsurer && (
            <div className="error_class">{errors.selectInsurer.message}</div>
          )}
        </div>
        <div className="col-4">
          {(veichleType[0] === "Comprehensive Policy" ||
            veichleType[0] === policyTypeOptions.SAOD) && (
            <Fragment>
              <Controller
                control={control}
                name="idv"
                render={({ field }) => (
                  <FormInput
                    field={field}
                    placeholder="Enter IDV"
                    type=""
                    inputMode={"currency"}
                  />
                )}
              />
              {errors.idv && (
                <div className="error_class">{errors.idv.message}</div>
              )}
            </Fragment>
          )}
        </div>
        {!showAddOns && (
          <div className="text-end view_label mt_16 d-flex justify-content-end ">
            <span
              className="cursor_pointer d-flex align-items-center gap-2 
w-fit-content
          "
              onClick={toggleAddOns}
            >
              <PlusCircle size={20} />
              <span>Add Ons</span>
            </span>
          </div>
        )}

        {showAddOns && (
          <Fragment>
            <Fragment>
              <div className="row mt-4 my-2 d-flex align-items-center">
                <div className="col-7 pb-0 mb-0">
                  <Controller
                    control={control}
                    name="addOns"
                    render={({ field }) => {
                      const { onChange, value, ...rest } = field;
                      return (
                        <MultiSelect
                          {...rest}
                          dataTestId={`select_addons`}
                          options={addOnOptions}
                          isSearchable
                          isClearable
                          className="select_dropdown"
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption)
                          }
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </Fragment>
            <div
              className="cursor_pointer
              w-fit-content
              error_class remove_addons_btn"
              onClick={handleToResetAddOns}
            >
              Remove Add Ons
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default RequestDetails;
