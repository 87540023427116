import React from "react";
import "./PolicyOfferCard.scss";
import { InsurerLogoMap } from "../../Constants/InsurerLogoMap";
import { Heart } from "react-feather";

const PolicyOfferCard = ({
  index,
  policyDetails,
  dealType,
  handleChangeFavourite,
}) => {
  return (
    <div className="policy-offer-card">
      {policyDetails.searchQuery && (
        <div className="favorite-card-header">{policyDetails.searchQuery}</div>
      )}
      <div className={`favorite-card ${dealType.toLowerCase()}`}>
        <div className="deal-type">
          {/* {dealType.split("_").join(" ").toLowerCase()} */}
          {dealType === "market_deal"
            ? "Normal Deal"
            : dealType.split("_").join(" ").toLowerCase()}
        </div>
        <div className="item insurer">
          {policyDetails.insurerId &&
          InsurerLogoMap[policyDetails.insurerId] ? (
            <img
              src={InsurerLogoMap[policyDetails.insurerId]?.Logo}
              alt={policyDetails.insurerName}
              className="logo_image"
            />
          ) : (
            <div class="logo_name">{policyDetails.insurerName}</div>
          )}
          <span className="insurer-id">Id - {policyDetails.dealId}</span>
        </div>
        <div className="item business-type">
          {policyDetails.businessType.join(", ")}
        </div>
        <div className="item parameters">
          {policyDetails.otherParams && policyDetails.otherParams.length > 0
            ? policyDetails.otherParams.map((param) => (
                <span className="parameter">{param}</span>
              ))
            : "-"}
        </div>
        <div className="item deal-percentage">
          {policyDetails.dealPercentages?.map((dealPercentage, index) => (
            <div key={index} className="deal-percentage-item">
              {dealPercentage}
            </div>
          ))}
        </div>

        <div className="favorite">
          <Heart
            className={`favorite-icon ${
              policyDetails.favouriteId ? "active" : ""
            }`}
            onClick={() => handleChangeFavourite(policyDetails, index)}
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyOfferCard;
