// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_PARTNER_MAIN_QUOTE_MODAL_DETAILS,
  GET_QUOTE_REQUEST_LIST,
  GET_QUOTE_UPLOAD_DOCUMENTS_DETAILS_FORM,
  GET_SHARED_QUOTE_LIST,
  SEND_FILL_QUOTE_DETAILS_FORM,
} from "../../../axios/apiendPoint";

const initialState = {
  isLoading: false,
  quoteRequestListingData: {},
  currentQuoteData: null,

  // Upload docs list
  quoteRequestUploadDocumnetList: null,

  // Shared quote list
  sharedQuoteList: null,

  // Partner quote main modal
  partnerQuoteMainModalDetails: null,

  // Modal States
  showQuoteDocModal: false,
  showViewQuoteModal: false,
  showInspectionDetailModal: false,
  showQuoteDetailModal: false,
  isQuoteChangeRequested: false,
};

// Fetch fetchQuoteRequestData LISTING data
export const fetchQuoteRequestData = createAsyncThunk(
  "quoteRequest/fetchQuoteRequestData",
  async (thunkparam) => {
    const { axiosGet } = thunkparam;
    const response = await axiosGet(GET_QUOTE_REQUEST_LIST);
    return { data: response?.data };
  },
);

// Fetch fetchQuoteRequestUploadDocumentsData LISTING data
export const fetchQuoteRequestUploadDocumentsData = createAsyncThunk(
  "quoteRequest/fetchQuoteRequestUploadDocumentsData",
  async (thunkparam) => {
    const { axiosGet, params } = thunkparam;
    const response = await axiosGet(
      GET_QUOTE_UPLOAD_DOCUMENTS_DETAILS_FORM,
      params,
    );
    return { data: response?.data };
  },
);

// Fetch fetchSharedQuote listing data
export const fetchSharedQuoteData = createAsyncThunk(
  "quoteRequest/fetchSharedQuoteData",
  async (thunkparam) => {
    const { axiosGet, params } = thunkparam;
    const response = await axiosGet(GET_SHARED_QUOTE_LIST, params);
    return { data: response?.data };
  },
);

// fetch partner quote main modal
export const fetchPartnerQuoteMainModalData = createAsyncThunk(
  "quoteRequest/fetchPartnerQuoteMainModalData",
  async (thunkparam) => {
    const { axiosGet, params } = thunkparam;
    const response = await axiosGet(
      GET_PARTNER_MAIN_QUOTE_MODAL_DETAILS,
      params,
    );
    return { data: response?.data };
  },
);

export const quoteRequestSlice = createSlice({
  name: "quoteRequest",
  initialState,
  reducers: {
    toggleQuoteDocModal: (state) => {
      state.showQuoteDocModal = !state.showQuoteDocModal;
    },
    toggleViewQuoteModal: (state) => {
      state.showViewQuoteModal = !state.showViewQuoteModal;
    },
    toggleFillInspectionModal: (state) => {
      state.showInspectionDetailModal = !state.showInspectionDetailModal;
    },
    toggleQuoteDetailModal: (state) => {
      state.showQuoteDetailModal = !state.showQuoteDetailModal;
    },

    setCurrentQuoteData: (state, action) => {
      state.currentQuoteData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // fetchQuoteRequestData
      .addCase(fetchQuoteRequestData.fulfilled, (state, action) => {
        state.quoteRequestListingData = action?.payload;
        state.isLoading = false;
      })
      .addCase(fetchQuoteRequestData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchQuoteRequestData.rejected, (state) => {
        state.isLoading = false;
      })

      // fetchQuoteRequestUploadDocumentsData
      .addCase(
        fetchQuoteRequestUploadDocumentsData.fulfilled,
        (state, action) => {
          state.quoteRequestUploadDocumnetList = action?.payload?.data;
          state.isLoading = false;
        },
      )
      .addCase(fetchQuoteRequestUploadDocumentsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchQuoteRequestUploadDocumentsData.rejected, (state) => {
        state.isLoading = false;
      })

      // fetchSharedQuoteData
      .addCase(fetchSharedQuoteData.fulfilled, (state, action) => {
        state.sharedQuoteList = action?.payload?.data;
        state.isLoading = false;
      })
      .addCase(fetchSharedQuoteData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSharedQuoteData.rejected, (state) => {
        state.isLoading = false;
      })

      // fetchPartnerQuoteMainModalData
      .addCase(fetchPartnerQuoteMainModalData.fulfilled, (state, action) => {
        state.partnerQuoteMainModalDetails = action?.payload?.data;
        state.isLoading = false;
      })
      .addCase(fetchPartnerQuoteMainModalData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPartnerQuoteMainModalData.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  toggleQuoteDocModal,
  toggleViewQuoteModal,
  toggleFillInspectionModal,
  toggleQuoteDetailModal,
  setCurrentQuoteData,
} = quoteRequestSlice.actions;

export default quoteRequestSlice.reducer;
