import React from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const LottiePlayer = ({ width, height, animationData, ...rest }) => {
  return (
    <div style={{ width: width, height: height }} {...rest}>
      <DotLottieReact
        src={URL.createObjectURL(
          new Blob([JSON.stringify(animationData)], {
            type: "application/json",
          }),
        )}
        autoplay
        loop
        autoResizeCanvas={false}
      />
    </div>
  );
};

export default LottiePlayer;
