import React from "react";
import "./index.scss";
import QuoteDynamicHeader from "../../../../../Components/QuoteDynamicHeader/QuoteDynamicHeader";
import Car from "../../../../../assets/lottie/car.json";

import AgencyDetails from "./AgencyDetails";

const InspectionProgress = () => {
  return (
    <div className="card_background p_16 pt-0">
      <QuoteDynamicHeader
        title="Inspection In progress !"
        subTitle="Our Agency will shortly complete the inspection."
        noCard
        customImage
        lottieImage={Car}
      />

      <AgencyDetails />
    </div>
  );
};

export default InspectionProgress;
