import React from "react";
import { ReactComponent as SearchIcon } from "../../assets/images/common/Search.svg";
import { useDispatch, useSelector } from "react-redux";
import { setSearchBoxValue } from "./SearchBoxReducer";
import "./SearchBox.scss";
const SearchBox = () => {
  const { seachboxValue, placeholder } = useSelector(
    (state) => state.searchBoxReducer,
  );
  const dispatch = useDispatch();
  const onChange = (e) => {
    dispatch(setSearchBoxValue(e.target.value));
  };

  return (
    <div className={`search_box_container`}>
      <div className="search_box_input">
        <input
          className="search_input"
          type="text"
          placeholder={placeholder}
          value={seachboxValue}
          onChange={onChange}
        />
      </div>
      <button type="button" className="search_icon">
        {/* <img
            src={ SearchIcon} 
            className="search_icon"
        /> */}
        <SearchIcon />
      </button>
    </div>
  );
};

export default SearchBox;
