import React, { Fragment } from "react";
import StartTraining from "./StartTraining";

const TrainingSection = () => {
  return (
    <Fragment>
      <StartTraining />
    </Fragment>
  );
};

export default TrainingSection;
