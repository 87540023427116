import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { quoteRequestActivityTime } from "../../../../Constants/Constants";
import Avatar from "../../../../assets/images/NewQuoteRequest/Avatar.svg";
import Avatar2 from "../../../../assets/images/NewQuoteRequest/Avatar2.svg";
import { ReactComponent as SendIcon } from "../../../../assets/images/NewQuoteRequest/send.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { POST_PARTNER_QUOTE_COMMENT } from "../../../../axios/apiendPoint";
import useAxiosPrivate from "../../../../axios/services";
import { toast } from "react-hot-toast";
import { fetchPartnerQuoteMainModalData } from "../../store/store";
import { useEffect } from "react";

const QuoteActivity = () => {
  const dispatch = useDispatch();
  const { axiosGet2, axiosPost2 } = useAxiosPrivate();

  const currentQuote = useSelector(
    (state) => state.quoteRequestReducer.currentQuoteData,
  );
  const details = useSelector(
    (state) => state.quoteRequestReducer.partnerQuoteMainModalDetails,
  );
  const { quoteCommentList = [] } = details || {};

  const detailSchema = yup.object().shape({
    comment: yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(detailSchema),
    defaultValues: {
      comment: "",
    },
  });

  // Handle form submission
  const onSubmit = async (data) => {
    const params = `quoteId=${currentQuote?.quoteId}`;
    const payload = { ...data, quoteId: currentQuote?.quoteId };
    if (!data?.comment || data?.comment?.trim() === "") {
      // set comment error
      setError("comment", {
        type: "manual",
        message: "Please enter comment",
      });
      return;
    } else {
      // reset comment error
      setError("comment", {
        type: "manual",
        message: "",
      });
    }

    const response = await axiosPost2(POST_PARTNER_QUOTE_COMMENT, payload);
    if (response?.success) {
      toast.success(response?.msg);
      reset();
      dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
    }
  };

  const rednerActivity = () => {
    return quoteCommentList?.map((item, index) => (
      <>
        <div className="activity_card mb_25" key={index}>
          {item.userType === "OPS" ? (
            <img src={Avatar} alt="avatar" className="avatar_image" />
          ) : (
            <img src={Avatar2} alt="avatar" className="avatar_image" />
          )}
          <div className="d-flex flex-column align-items-start justify-content-start gap-1">
            <div className="quote_requested">{item?.comment}</div>
            <div className="quote_timestamp">
              {quoteRequestActivityTime(item?.timestamp)}
            </div>
          </div>
        </div>
      </>
    ));
  };

  useEffect(() => {
    rednerActivity();
  }, [quoteCommentList]);

  return (
    <div className="quote_activity_container">
      <p className="component_section_name mb_12">Activity</p>

      <div className="d-flex flex-column justify-content-between h-100">
        <div className="activity_content">{rednerActivity()}</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="comment_input_container">
            <Controller
              control={control}
              name="comment"
              render={({ field }) => (
                <Input
                  placeholder="Comment Here"
                  {...field}
                  className="comment_input"
                />
              )}
            />
            <div className="send_icon" onClick={handleSubmit(onSubmit)}>
              <SendIcon />
            </div>
          </div>
          {errors.comment && (
            <div className="error_class">{errors.comment.message}</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default QuoteActivity;
