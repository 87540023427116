import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GRID_GLOSSARY } from "../../../../axios/apiendPoint";

const initialState = {
  isLoading: false,
  glossary: [],
  error: null,
};

export const FetchGlossaryData = createAsyncThunk(
  "glossary/FetchGlossaryData",
  async ({ axiosGet }) => {
    const response = await axiosGet(GRID_GLOSSARY);
    return response.data; // correct part of the response
  },
);

const glossarySlice = createSlice({
  name: "glossary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchGlossaryData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(FetchGlossaryData.fulfilled, (state, action) => {
        state.glossary = action.payload; // Assign fetched data to glossary
        state.isLoading = false;
      })
      .addCase(FetchGlossaryData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default glossarySlice.reducer;
