import React from "react";
import BlurOverlay from "../../../Components/BlurOverlay/BlurOverlay";
import { ReactComponent as LockIcon } from "../../../assets/images/Dashboard/lock.svg";
const LockScreen = () => {
  return (
    <div className="lock-screen">
      <div className="lock-screen-content">
        <LockIcon color="#DF0000" />
        <h2>Dashboard is locked</h2>
        <p>Please complete your KYC to book your policies!</p>
      </div>
      <BlurOverlay
        position="absolute"
        left="-24px"
        width="calc(100% + 48px)"
        height="calc(100% + 24px)"
        minHeight="calc(100vh - 240px)"
        blurClass="show"
      />
    </div>
  );
};

export default LockScreen;
