import React from "react";
import QuoteDynamicHeader from "../../../../../Components/QuoteDynamicHeader/QuoteDynamicHeader";
import Clock from "../../../../../assets/lottie/searching.json";

const CaseClosed = () => {
  return (
    <div>
      <QuoteDynamicHeader
        title="Case Closed !"
        subTitle="Case Closed! Please Request New Quote"
      />
    </div>
  );
};

export default CaseClosed;
