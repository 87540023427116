// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_MODAL_STATES } from "../../../../axios/apiendPoint";

const initialState = {
  isLoading: false,
  modalStateDetails: {},
  entityData: [],
  currentModal: null,

  // aadhar details modal
  isAadharDetailsModalOpen: false,
  isAadharValid: false,
  aadharDetails: {},
  isPartnerRegistered: true,
};

// Fetch dashboardModalSection data
export const fetchdashboardModalStates = createAsyncThunk(
  "dashboardModalSection/fetchdashboardModalStates",
  async (thunkparam) => {
    const { axiosGet } = thunkparam;
    const response = await axiosGet(GET_MODAL_STATES);
    return { data: response };
  },
);

export const dashboardModalSectionSlice = createSlice({
  name: "dashboardModalSection",
  initialState,
  reducers: {
    setCurrentModal: (state, action) => {
      state.currentModal = action.payload;
    },
    setIsAadharModalDetailsToggle: (state) => {
      state.isAadharDetailsModalOpen = !state.isAadharDetailsModalOpen;
    },
    setIsAadharValid: (state, action) => {
      state.isAadharValid = action.payload;
    },
    setAadharDetails: (state, action) => {
      state.aadharDetails = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // fetchdashboardModalStates
      .addCase(fetchdashboardModalStates.fulfilled, (state, action) => {
        state.modalStateDetails = action?.payload?.data;
        state.isLoading = false;
        state.currentModal = action?.payload?.data?.data?.page;
        state.isPartnerRegistered =
          action?.payload?.data?.data?.partnerRegistered;
      })
      .addCase(fetchdashboardModalStates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchdashboardModalStates.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setCurrentModal,
  setIsAadharModalDetailsToggle,
  setIsAadharValid,
  setAadharDetails,
} = dashboardModalSectionSlice.actions;

export default dashboardModalSectionSlice.reducer;
