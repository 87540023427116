import React from "react";
import "./index.scss";
import CustomField from "../../Components/CustomFields/CustomFields";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";
import NewVeichle from "./NewVeichle";
import OldVeichle from "./OldVeichle";
import { setShowRequestNewQuoteModal } from "./store/store";
import { useDispatch } from "react-redux";

export const isVeichleState = ["Vehicle is New", "Vehicle is old"];

const NewQuoteForm = ({
  veichleState,
  setVeichleState,
  handleSubmit,
  handleToSubmit,
  errors,
  control,
  reset,
  resetField,
  veichleType,
  setVeichleType,
  previousPolicyFlag,
  setPreviousPolicyFlag,
  claimPolicyFlag,
  setClaimPolicyFlag,
  activeHeader,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="newQuoteRequestFormContainer ">
      <form className="min_height_form" onSubmit={handleSubmit(handleToSubmit)}>
        <div className="form_data">
          <div className="py_16">
            <CustomField
              value={veichleState}
              setvalue={setVeichleState}
              options={isVeichleState}
            />
          </div>
          {veichleState[0] === "Vehicle is New" ? (
            <NewVeichle
              errors={errors}
              control={control}
              veichleState={veichleState}
              previousPolicyFlag={previousPolicyFlag}
              setVeichleType={setVeichleType}
              veichleType={veichleType}
              activeHeader={activeHeader}
              resetField={resetField}
            />
          ) : (
            <OldVeichle
              errors={errors}
              control={control}
              veichleState={veichleState}
              setVeichleType={setVeichleType}
              veichleType={veichleType}
              previousPolicyFlag={previousPolicyFlag}
              setPreviousPolicyFlag={setPreviousPolicyFlag}
              claimPolicyFlag={claimPolicyFlag}
              setClaimPolicyFlag={setClaimPolicyFlag}
              activeHeader={activeHeader}
              resetField={resetField}
            />
          )}
        </div>
        <div className="new_quote_form_buttons">
          <div className="form_footer d-flex align-items-center justify-content-between">
            <CustomButton
              buttonName="Cancel"
              btnNameClass="cancel_btn_name"
              className="cancel_btn"
              handleClick={() => {
                reset();
                dispatch(setShowRequestNewQuoteModal());
              }}
              alignCenter
            />
            <CustomButton
              className="submit_btn button "
              type="submit"
              alignCenter
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewQuoteForm;
