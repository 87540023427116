import React, { useState } from "react";
import "./index.scss";
import CustomModal from "../../../Components/Modal/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuoteRequestData,
  setCurrentQuoteData,
  toggleFillInspectionModal,
} from "../store/store";
import CustomButton from "../../../Components/Button/CustomButton/CustomButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import FormInput from "../../../Components/FormInput/FormInput";
import Mail from "../../../assets/images/common/email.svg";
import Phone from "../../../assets/images/common/phone.svg";
import Home from "../../../assets/images/common/home.svg";
import useAxiosPrivate from "../../../axios/services";
import { SEND_FILL_QUOTE_DETAILS_FORM } from "../../../axios/apiendPoint";
import { toast } from "react-hot-toast";
import { GET_PIN_CODE_CITY } from "../../../axios/apiendPoint";

const FillDetailsModal = () => {
  const dispatch = useDispatch();
  const { axiosGet2, axiosPost2, axiosGet } = useAxiosPrivate();

  const isOpen = useSelector(
    (state) => state.quoteRequestReducer.showInspectionDetailModal,
  );
  const currentQuote = useSelector(
    (state) => state.quoteRequestReducer.currentQuoteData,
  );
  const [city, SetCity] = useState(null);

  const toggle = () => dispatch(toggleFillInspectionModal());

  const detailSchema = yup.object().shape({
    // phone: yup
    //   .string()
    //   .matches(/^[0-9]{10}$/, "Phone number is not valid")
    //   .required("Phone number is required"),
    // email: yup.string().email("Invalid email address").required("Email is required"),
    address: yup.string().required("Address is required"),
    pincode: yup
      .string()
      .matches(/^[0-9]{6}$/, "Pin code is not valid")
      .required("Pin code is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(detailSchema),
    defaultValues: {
      // phone: "",
      // email: "",
      address: "",
      pincode: "",
    },
  });

  // Handle form submission
  const onSubmit = async (data) => {
    const payload = { ...data, quoteId: currentQuote?.quoteId };
    const response = await axiosPost2(SEND_FILL_QUOTE_DETAILS_FORM, payload);
    if (response?.success) {
      toast.success(response?.msg);
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
      dispatch(setCurrentQuoteData(null));
      reset();
      toggle();
    }
  };

  const handleToGetPinCode = async (e) => {
    let pincode = e.target.value;
    if (pincode.length === 6) {
      const response = await axiosGet(GET_PIN_CODE_CITY, `pincode=${pincode}`);
      if (response?.success) {
        SetCity(response.data);
      } else {
        setError("pincode", {
          type: "manual",
          message: "Pin code is not valid",
        });
        SetCity(null);
      }
    } else {
      SetCity(null);
      setError("pincode", {
        type: "manual",
        message: "Pin code is not valid",
      });
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      width="512px"
      modaltitle="Fill Customer Details for inspection"
      modalbody={
        <div className="quote_requeste_doc_upload_container">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="my-3">
              <Controller control={control} name="email" render={({ field }) => <FormInput field={field} icon={<img src={Mail} alt="email" />} placeholder="Customer Email ID" />} />
              {errors.email && <div className="error_class">{errors.email.message}</div>}
            </div>

            <div className="my-3">
              <Controller control={control} name="phone" render={({ field }) => <FormInput field={field} icon={<img src={Phone} alt="phone" />} placeholder="customer Mobile No." />} />
              {errors.phone && <div className="error_class">{errors.phone.message}</div>}
            </div> */}

            <div className="my-3">
              <Controller
                control={control}
                name="address"
                render={({ field }) => (
                  <FormInput
                    field={field}
                    icon={<img src={Home} alt="home" />}
                    placeholder="Enter Address"
                  />
                )}
              />
              {errors.address && (
                <div className="error_class">{errors.address.message}</div>
              )}
            </div>

            <div className="my-3">
              <div className="my-3">
                <Controller
                  control={control}
                  name="pincode"
                  render={({ field }) => (
                    <FormInput
                      field={field}
                      handleChange={handleToGetPinCode}
                      placeholder="Enter Your area pincode"
                    />
                  )}
                />
              </div>
              {city && (
                <p className="fill_details_pin_code">{`${city.city}, ${city.state}`}</p>
              )}
              {errors.pincode && (
                <div className="error_class">{errors.pincode.message}</div>
              )}
            </div>

            <div className="d-flex align-items-center justify-content-end my-4">
              <CustomButton type="submit" alignCenter />
            </div>
          </form>
        </div>
      }
    />
  );
};

export default FillDetailsModal;
