import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GET_DASHBOARD_DATA } from "../../../axios/apiendPoint";
import { fetchDashboardData } from "../dashboardStore/store";
import useAxiosPrivate from "../../../axios/services";
import { Calendar } from "react-feather";
import BookingsCard from "./BookingsCard";
import MappingsCard from "./MappingsCard";
import QuotesCard from "./QuotesCard";
import { isMobileDevice } from "../../../utils/utils";
import Notes from "../../../Components/Notes/Notes";
const DashboardData = () => {
  // set default empty state for dashboardData and isLoading
  const { dashboardData = {}, isLoading } = useSelector(
    (state) => state.dashboardRootReducer,
  );
  const dispatch = useDispatch();
  const { axiosGet2 } = useAxiosPrivate();
  useEffect(() => {
    dispatch(fetchDashboardData({ axiosGet: axiosGet2 }));
  }, []);
  const navigate = useNavigate();
  const handleClick = (prop) => {
    navigate.to(`/${prop}`);
  };
  const [dateSelected, setDateSelected] = useState(new Date());

  useEffect(() => {
    if (dateSelected) {
      const date = new Date(dateSelected);
      dispatch(
        fetchDashboardData({
          axiosGet: axiosGet2,
          params: `month=${date.getMonth()}&year=${date.getFullYear()}`,
        }),
      );
    }
  }, [dateSelected]);
  const handleDateChange = (date) => {
    setDateSelected(date);
  };

  return (
    <div className="dashboard-wrapper">
      <div className="date_container">
        <div className="user-name">
          👋 Hi {dashboardData.customerName}, sell and earn more
        </div>
        {!isMobileDevice() && (
          <div className="date_picker_container">
            <Flatpickr
              placeholder="Month-Year"
              options={{
                plugins: [
                  new monthSelectPlugin({
                    shorthand: true,
                    dateFormat: "m/Y",
                    altInput: true,
                    altFormat: "m/Y",
                    theme: "light",
                  }),
                ],
                static: true,
              }}
              className="flat_picker_date"
              style={{ width: "250px" }}
              value={null} // Remove the value prop or set it to null to prevent preselecting a date
              onChange={handleDateChange}
            />
            <Calendar color="#0691FC" className="margin__30" />
          </div>
        )}
      </div>
      <div className="main-div">
        {/* <div> */}
        <BookingsCard
          type={"Bookings"}
          cardDetails={dashboardData.bookingDetails}
          recentDetails={dashboardData.bookingList}
        />
        <MappingsCard
          type={"Mappings"}
          cardDetails={dashboardData.mappingDetails}
          recentDetails={dashboardData.mappingList}
        />
        <QuotesCard
          type={"QuoteRequest"}
          cardDetails={dashboardData.quoteDetails}
          recentDetails={dashboardData.quoteList}
        />
      </div>
      {/* <Notes/> */}
    </div>
  );
};

export default DashboardData;
