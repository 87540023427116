import React from "react";
import { ChevronDown } from "react-feather";
import Profile from "../../../assets/images/navbar/user.svg";
import BlurOverlay from "../../BlurOverlay/BlurOverlay";
import { ReactComponent as LogoutIcon } from "../../../assets/images/common/logout.svg";

// UserDropdown component to display user details in the navbar and logout button on click modal will open
const UserDropdown = ({ name, userId }) => {
  const [logoutModal, setLogoutModal] = React.useState(false);

  const handleLogout = () => {
    // clear cookies and redirect to login page
    setLogoutModal(false);
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${process.env.REACT_APP_PUBLIC_DOMAIN}; path=/;`;
    window.location.href = `${process.env.REACT_APP_LANDING_WEBSITE_URL}`;
  };
  return (
    <div className="navbar_user_detail_border d-flex  align-items-center justify-content-center cursor_pointer">
      <img src={Profile} alt="help" className="user_image" />
      <div
        className="d-flex flex-column align-items-start justify-content-center"
        onClick={() => setLogoutModal(true)}
      >
        <span className="m-0 p-0 user_name">
          {name} <ChevronDown />
        </span>
        <span className="m-0 p-0 user_id">{userId}</span>
      </div>
      {logoutModal && (
        <>
          <div className="logout_menu cursor_pointer">
            <div className="menu_item" onClick={() => handleLogout()}>
              <LogoutIcon className="logout_icon" />

              <span className="menu_heading"> Logout</span>
            </div>
          </div>
          <BlurOverlay
            isBlur={true}
            onOuterClick={() => setLogoutModal(false)}
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
          />
        </>
      )}
    </div>
  );
};

export default UserDropdown;
