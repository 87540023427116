// ** Reducers Imports
import dashboardModalReducer from "../Pages/Dashboard/ModalSection/modalSectionStore/store";
import dashboardRootReducer from "../Pages/Dashboard/dashboardStore/store";
import trainingModalReducer from "../Pages/Dashboard/TrainingSection/TrainingModal/trainingModalStore/store";
import quoteRequestReducer from "../Pages/QuoteRequest/store/store";
import newQuoteRequestReducer from "../Pages/RequestNewQuote/store/store";
import { searchBoxReducer } from "../Components/SearchBox/SearchBoxReducer";
import mappingRequestReducer from "../Pages/MappingRequest/store/store";
import bookingReducer from "../Pages/Bookings/Store/store";
import glossaryReducer from "../Components/Layout/Navbar'/store/store";
import gridReducer from "../Pages/Grid/Store/store";

const rootReducer = {
  dashboardModalReducer,
  dashboardRootReducer,
  trainingModalReducer,
  quoteRequestReducer,
  newQuoteRequestReducer,
  searchBoxReducer,
  mappingRequestReducer,
  bookingReducer,
  glossaryReducer,
  gridReducer,
};

export default rootReducer;
