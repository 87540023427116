import React, { useEffect, useState } from "react";
import QuoteTable from "./QuoteTable";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearchBoxPlaceHolder,
  setSearchBoxValue,
  setShowSearchBox,
} from "../../Components/SearchBox/SearchBoxReducer";
import { fetchQuoteRequestData } from "./store/store";
import useAxiosPrivate from "../../axios/services";
import MultiSelect from "../../Components/MultiSelect/MultiSelect";
import "./index.scss";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { Calendar } from "react-feather";
import { quoteRequestListingStatusObj } from "../../Constants/Constants";

const QuoteRequest = () => {
  const { axiosGet2 } = useAxiosPrivate();
  const searchBoxValue = useSelector(
    (state) => state.searchBoxReducer.seachboxValue,
  );
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(setSearchBoxValue(""));
    dispatch(setSearchBoxPlaceHolder("Search by Ticket ID, Reg no."));
  }, []);

  const filterOptions = Object.entries(quoteRequestListingStatusObj).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const [filteredData, setFilteredData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handleFilterBy = (option) => {
    if (option && option.value) {
      setFilterBy(option.value);
    } else {
      setFilterBy("");
    }
  };

  const handleDateChange = (date) => {
    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const sortByQuoteCreationTime = (array, sortOrder) => {
    const sortedArray = JSON.parse(JSON.stringify(array)); // Create a deep copy to avoid modifying the original array

    sortedArray.sort((a, b) => {
      const dateA = new Date(a.quoteCreationTime);
      const dateB = new Date(b.quoteCreationTime);

      if (sortOrder === "asc") {
        return dateA - dateB;
      } else if (sortOrder === "desc") {
        return dateB - dateA;
      }
    });

    return sortedArray;
  };

  const { isLoading, quoteRequestListingData } = useSelector(
    (state) => state.quoteRequestReducer,
  );

  const getQuoteRequestDetails = () =>
    dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));

  useEffect(() => {
    getQuoteRequestDetails();
  }, []);

  useEffect(() => {
    // on data change set the filtered data
    if (
      quoteRequestListingData &&
      quoteRequestListingData.data &&
      quoteRequestListingData.data.length > 0
    ) {
      setData(quoteRequestListingData.data);
      setSortBy("");
      setFilterBy("");
      setFilterByDate({ startDate: "", endDate: "" });
    }
  }, [quoteRequestListingData]);

  useEffect(() => {
    let filterData = data;
    if (
      searchBoxValue &&
      searchBoxValue.length > 0 &&
      data &&
      data.length > 0
    ) {
      filterData = data.filter((item) => {
        const { quoteId, registrationNumber, requestId } = item;
        const searchValue = searchBoxValue.toLowerCase();
        return (
          quoteId?.toLowerCase().includes(searchValue) ||
          registrationNumber?.toLowerCase().includes(searchValue) ||
          requestId?.toLowerCase().includes(searchValue)
        );
      });
    }
    if (
      filterBy &&
      filterBy.length > 0 &&
      filterData &&
      filterData.length > 0
    ) {
      filterData = filterData.filter((item) => {
        const { quoteState } = item;
        const filterValue = filterBy.toLowerCase();
        return quoteState?.toLowerCase().includes(filterValue.toLowerCase());
      });
    }

    if (
      filterByDate &&
      filterByDate.startDate &&
      filterByDate.endDate &&
      filterData &&
      filterData.length > 0
    ) {
      filterData = filterData.filter((item) => {
        const { quoteCreationTime } = item;
        const startDate = new Date(filterByDate.startDate).setHours(0, 0, 0, 0);
        const endDate = new Date(filterByDate.endDate).setHours(
          23,
          59,
          59,
          999,
        );
        const quoteCreationTimeDate = new Date(quoteCreationTime).getTime();
        return (
          quoteCreationTimeDate >= startDate && quoteCreationTimeDate <= endDate
        );
      });
    }

    if (filterData && filterData.length > 1) {
      filterData = sortByQuoteCreationTime(filterData, sortBy);
    }
    setFilteredData(filterData);
  }, [searchBoxValue, filterBy, sortBy, data, filterByDate]);

  return (
    <div className="">
      {/* Header Section */}
      <div className="filters_container">
        <div className="filter_sort_status">
          {/* Sort By dropdown  */}
          {/* <button className="sort_by_btn" onClick={() => setSortBy(sortBy === "asc" ? "desc" : "asc")}>
            Sort By <span className="sort_by_arrow">{sortBy === "asc" ? "Asc": ""}</span>
          </button> */}

          {/* Sort by dropdown */}
          <MultiSelect
            options={[
              { label: "Newest First", value: "desc" },
              { label: "Oldest First", value: "asc" },
            ]}
            // defaultValue={{ label: "Newest First", value: "desc" }}
            onChange={(option) =>
              option && option.value ? setSortBy(option.value) : setSortBy("")
            }
            isClearable={true}
            placeholder="Sort By"
            isMulti={false}
            className="sort_by_dropdown"
          />

          <MultiSelect
            options={filterOptions}
            selectedOptions={[]}
            onChange={handleFilterBy}
            isClearable={true}
            placeholder="Status"
            isMulti={false}
            className="filter_by_dropdown"
          />
        </div>

        <div className="date_container">
          <div className="date_picker_container">
            <Flatpickr
              placeholder="Date Range"
              options={{
                dateFormat: "d-m-Y",
                maxDate: new Date(),
                mode: "range",
              }}
              className="flat_picker_date"
              style={{ width: "250px" }}
              value={
                filterByDate.startDate && filterByDate.endDate
                  ? [filterByDate.startDate, filterByDate.endDate]
                  : ""
              }
              onChange={handleDateChange}
            />
            <Calendar color="#0691FC" className="margin__30" />
          </div>
        </div>
      </div>

      {/* Table Section */}
      <QuoteTable data={filteredData} isLoading={isLoading} />
    </div>
  );
};

export default QuoteRequest;
