// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_BOOKING_LIST,
  GET_BOOKING_MODAL_DATA,
} from "../../../axios/apiendPoint";

const initialState = {
  isLoading: false,
  isLoadingModalData: false,
  bookingData: [],
  bookingModalData: {},
  isBookingModalOpen: false,
};

export const fetchBookingModalData = createAsyncThunk(
  "bookingRequest/fetchBookingModalData",
  async (thunkparam) => {
    const { axiosGet, params } = thunkparam;
    const response = await axiosGet(GET_BOOKING_MODAL_DATA, params);
    return { data: response?.data };
  },
);

// Fetch Booking LISTING data
export const fetchBookingData = createAsyncThunk(
  "bookingRequest/fetchBookingData",
  async (thunkparam) => {
    const { axiosGet } = thunkparam;
    const response = await axiosGet(GET_BOOKING_LIST);
    return { data: response?.data };
  },
);

export const bookingDataSlice = createSlice({
  name: "bookingRequest",
  initialState,
  reducers: {
    setIsBookingModalOpen: (state, action) => {
      state.isBookingModalOpen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchBookingData.fulfilled, (state, action) => {
      state.bookingData = action?.payload.data;
      state.isLoading = false;
    });
    builder.addCase(fetchBookingData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBookingData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchBookingModalData.fulfilled, (state, action) => {
      state.bookingModalData = action?.payload.data;
      state.isLoadingModalData = false;
      state.isBookingModalOpen = true;
    });
    builder.addCase(fetchBookingModalData.pending, (state) => {
      state.isLoadingModalData = true;
    });
    builder.addCase(fetchBookingModalData.rejected, (state) => {
      state.isLoadingModalData = false;
    });
  },
});
export const { setIsBookingModalOpen } = bookingDataSlice.actions;
//write export statements
export default bookingDataSlice.reducer;
