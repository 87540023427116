import React from "react";
import "./index.scss";
import File from "../../assets/images/NewQuoteRequest/File.svg";
import LottiePlayer from "../LottiePlayer/LottiePlayer";

const QuoteDynamicHeader = ({
  title,
  subTitle,
  titleClass,
  subTitleClass,
  noCard,
  customImage,
  lottieImage,
}) => {

  return (
    <div
      className={
        noCard ? "quote_dynamic_header_no_background" : "quote_dynamic_header"
      }
    >
      {customImage && (
        <LottiePlayer animationData={lottieImage} height={126} width={126} />
      )}
      {!customImage && <img src={File} alt="file" />}
      <div className={titleClass ? titleClass : "header_title"}>{title}</div>
      <div
        className={
          subTitleClass ? `${subTitleClass} py_8` : "header_introduction py_8"
        }
      >
        {subTitle}
      </div>
    </div>
  );
};

export default QuoteDynamicHeader;
