import React, { Fragment, useState, useEffect } from "react";
import "./index.scss";
import CustomModal from "../CustomModal/CustomModal";
import VerifiedModal from "../VerifiedModal/VerifiedModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import BasicInput from "../../BasicInput/BasicInput";
import Looking from "../../../assets/lottie/looking.json";
import CustomButton from "../../Button/CustomButton/CustomButton";
import useAxiosPrivate from "../../../axios/services";
import {
  SEND_BANK_DETAILS,
  UPLOAD_CANCEL_CHEQUE,
} from "../../../axios/apiendPoint";
import UploadButton from "../../Button/UploadButton/UploadButton";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchdashboardModalStates } from "../../../Pages/Dashboard/ModalSection/modalSectionStore/store";
import CheckCircle from "../../../assets/images/common/check_circle.svg";
import Loader from "../../Loader/Loader";
import LottiePlayer from "../../LottiePlayer/LottiePlayer";

const BankDetailsModal = (props) => {
  const { isOpen, toggle } = props || {};

  const modalDetails = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data;
  const { page, ifsc = null, bankAccountNumber = null } = modalDetails || {};

  const dispatch = useDispatch();
  const { axiosPost, axiosFormDataPost, axiosGet, isLoading } =
    useAxiosPrivate();

  const [isBankDetailASuccess, setIsBankDetailASuccess] = useState(false);
  const [isIfscLoading, setIsIfscLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [bankSubmit, setBankSubmit] = useState(false);
  const [isChequeSubmit, setIsChequeSubmit] = useState(false);

  const schema = yup.object().shape({
    accountNumber: yup.string().required("Account number is required"),
    ifsc: yup.string().required("IFSC code is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      accountNumber: bankAccountNumber ? bankAccountNumber : "",
      ifsc: ifsc ? ifsc : "",
    },
  });

  const handleToSubmitBankDetails = async (data) => {
    const payload = {
      accountNumber: data?.accountNumber,
      ifsc: data?.ifsc,
      consentText:
        "I hear by declare my consent agreement for fetching my information via ZOOP API",
      consent: true,
    };

    const response = await axiosPost(SEND_BANK_DETAILS, payload);
    setIsIfscLoading(true);
    if (response?.success) {
      toast.success(response?.msg);
      setBankSubmit(true);
      dispatch(fetchdashboardModalStates({ axiosGet }));
    } else {
      setBankSubmit(false);
    }
    setIsIfscLoading(false);
  };

  const handleToCheckVerifyBankStatus = () => {
    if (!bankSubmit) toast.error("Kindly verify your account first");
  };

  const handleToUploadCheck = async (fileToUpload) => {
    if (bankSubmit && fileToUpload) {
      const formData = new FormData();
      formData.append("file", fileToUpload);
      const response = await axiosFormDataPost(UPLOAD_CANCEL_CHEQUE, formData);
      if (response?.success) {
        toast.success(response?.msg);
        setIsChequeSubmit(true);
        setFileData(null);
        dispatch(fetchdashboardModalStates({ axiosGet }));
      } else {
        setIsChequeSubmit(false);
        setFileData(null);
        dispatch(fetchdashboardModalStates({ axiosGet }));
      }
    }
  };

  useEffect(() => {
    if (page === "CANCEL_CHEQUE_UPLOAD_PAGE") setBankSubmit(true);
  }, [page]);

  const handleToSubmit = () => {
    if (isChequeSubmit) {
      reset();
      setIsBankDetailASuccess(true);
      setBankSubmit(false);
      setFileData(null);
      setIsChequeSubmit(false);
      setTimeout(() => {
        toggle();
      }, 2000);
    } else {
      toast.error("Kindly fill the details");
    }
  };

  return (
    <div className="bank_details_modal_container">
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        width={"592px"}
        modaltitle={
          isBankDetailASuccess ? null : (
            <span className="bank_account_modal_heading">
              Bank Account details
            </span>
          )
        }
        modalbody={
          isLoading ? (
            <div className="loader_container">
              <Loader />
            </div>
          ) : isBankDetailASuccess ? (
            <VerifiedModal verifiedText="Bank details Submitted Successfully!" />
          ) : (
            <Fragment>
              <form onSubmit={handleSubmit(handleToSubmitBankDetails)}>
                <BasicInput
                  name="accountNumber"
                  errors={errors}
                  register={register("accountNumber")}
                  placeholder="Enter your bank account number *"
                  // errorclass="pan_name"
                  isDisabled={bankAccountNumber ? true : false}
                />

                {!isIfscLoading ? (
                  <div className="mt-4">
                    <span className={"input_group pan_number"}>
                      <input
                        id="ifsc"
                        name="ifsc"
                        {...register("ifsc")}
                        disabled={ifsc ? true : false}
                        invalid={errors?.ifsc}
                        placeholder="Enter IFSC Code *"
                        className="input"
                      />
                      {bankSubmit ? (
                        <div className="verified_pan_status">
                          <img src={CheckCircle} alt="check" height="15px" />{" "}
                          Verified
                        </div>
                      ) : (
                        <button className="verify_pan_btn" type="submit">
                          Verify
                        </button>
                      )}
                    </span>
                    <span className="error_class">
                      {errors?.ifsc && <small> {errors?.ifsc?.message}</small>}
                    </span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-start my-4">
                    <div className="m-0 p-0">
                      <LottiePlayer
                        animationData={Looking}
                        height={41}
                        width={96}
                      />
                    </div>
                    <p className="looking_for d-flex align-items-center justify-content-center p-0 m-0">
                      We are matching your Bank details..
                    </p>
                  </div>
                )}
              </form>

              <div className="my-5 d-flex align-items-center justify-content-between">
                <p className="m-0 p-0 upload_cancel_cheque_content">
                  Upload Cancelled Bank cheque
                </p>
                <UploadButton
                  handleSubmit={handleToUploadCheck}
                  extrHandleClick={handleToCheckVerifyBankStatus}
                  setFileData={setFileData}
                  isDisabled={!bankSubmit}
                />
              </div>
              <CustomButton
                alignCenter
                fullWidth
                handleClick={handleToSubmit}
              />
            </Fragment>
          )
        }
      />
    </div>
  );
};

export default BankDetailsModal;
