import HDFCGenImg from "../assets/images/Insurers/Hdfc Ergo.svg";
import CholaImg from "../assets/images/Insurers/Cholamandalam.svg";
import GoDigitImg from "../assets/images/Insurers/Digit.svg";
import BajajImg from "../assets/images/Insurers/Bajaj Allianz.svg";
import ICICIImg from "../assets/images/Insurers/ICICI Lombard.svg";
import RelianceImg from "../assets/images/Insurers/Reliance.svg";
import TATAImg from "../assets/images/Insurers/Tata AIG.svg";
import SBIGenImg from "../assets/images/Insurers/SBI General.svg";
import IFFCOImg from "../assets/images/Insurers/Iffco Tokio.svg";
import FutureImg from "../assets/images/Insurers/Future Generali.svg";
import LibertyImg from "../assets/images/Insurers/Liberty Videocon.svg";
import ShriramImg from "../assets/images/Insurers/Shri Ram.svg";
// no image for zuno
import ZunoImg from "../assets/images/Insurers/Zuno.svg";
import UnitedImg from "../assets/images/Insurers/UnitedIndia.svg";
import NationalImg from "../assets/images/Insurers/National Insurance.svg";
import MagmaImg from "../assets/images/Insurers/Magma HDI.svg";
// rsa
import RSAImg from "../assets/images/Insurers/Royal Subdaram.svg";
import UniversalSompooImg from "../assets/images/Insurers/usgi-logo.svg";
import KotakMahindra from "../assets/images/Insurers/Kotak Mahindra.svg";

export const InsurerLogoMap = {
  "INSURER-1": {
    insurerId: "INSURER-1",
    Logo: HDFCGenImg,
  },
  "INSURER-2": {
    insurerId: "INSURER-2",
    Logo: CholaImg,
  },
  "INSURER-3": {
    insurerId: "INSURER-3",
    Logo: GoDigitImg,
  },
  "INSURER-4": {
    insurerId: "INSURER-4",
    Logo: BajajImg,
  },
  "INSURER-5": {
    insurerId: "INSURER-5",
    Logo: ICICIImg,
  },
  "INSURER-6": {
    insurerId: "INSURER-6",
    Logo: RelianceImg,
  },
  "INSURER-7": {
    insurerId: "INSURER-7",
    Logo: TATAImg,
  },
  "INSURER-8": {
    insurerId: "INSURER-8",
    Logo: SBIGenImg,
  },
  "INSURER-9": {
    insurerId: "INSURER-9",
    Logo: IFFCOImg,
  },
  "INSURER-10": {
    insurerId: "INSURER-10",
    Logo: FutureImg,
  },
  "INSURER-11": {
    insurerId: "INSURER-11",
    Logo: LibertyImg,
  },
  "INSURER-12": {
    insurerId: "INSURER-12",
    Logo: ShriramImg,
  },
  "INSURER-13": {
    insurerId: "INSURER-13",
    Logo: ZunoImg,
  },
  "INSURER-14": {
    insurerId: "INSURER-14",
    Logo: UnitedImg,
  },
  "INSURER-15": {
    insurerId: "INSURER-15",
    Logo: NationalImg,
  },
  "INSURER-16": {
    insurerId: "INSURER-16",
    Logo: MagmaImg,
  },
  "INSURER-17": {
    insurerId: "INSURER-17",
    Logo: RSAImg,
  },
  "INSURER-18": {
    insurerId: "INSURER-18",
    Logo: UniversalSompooImg,
  },
  "INSURER-19": {
    insurerId: "INSURER-19",
    Logo: KotakMahindra,
  },
};
