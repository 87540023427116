// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_INSURERS_ADD_ONS_LIST_FOR_NEW_QUOTE_REQUEST } from "../../../axios/apiendPoint";

const initialState = {
  isLoading: false,
  showNewQuoteRequestMenu: false,
  showRequestNewQuoteModal: false,
  insurerList: [],
  addOnsList: [],
  showPolicyMappingModal: false,
};

// Fetch new quote insurers data
export const fetchNewQuoteInsurersAddOnsList = createAsyncThunk(
  "newQuoteRequest/fetchNewQuoteInsurersAddOnsList",
  async (thunkparam) => {
    const { axiosGet, params } = thunkparam;
    const response = await axiosGet(
      GET_INSURERS_ADD_ONS_LIST_FOR_NEW_QUOTE_REQUEST,
      params,
    );
    return { data: response };
  },
);

export const newQuoteRequestSlice = createSlice({
  name: "newQuoteRequest",
  initialState,
  reducers: {
    setShowNewQuoteRequestMenu: (state) => {
      state.showNewQuoteRequestMenu = !state.showNewQuoteRequestMenu;
    },
    setShowRequestNewQuoteModal: (state) => {
      if (state.showNewQuoteRequestMenu) {
        state.showNewQuoteRequestMenu = !state.showNewQuoteRequestMenu;
      }
      state.showRequestNewQuoteModal = !state.showRequestNewQuoteModal;
    },
    setShowPolicyMappingModal: (state) => {
      if (state.showNewQuoteRequestMenu) {
        state.showNewQuoteRequestMenu = !state.showNewQuoteRequestMenu;
      }
      state.showPolicyMappingModal = !state.showPolicyMappingModal;
    },
  },

  extraReducers: (builder) => {
    builder
      // fetchNewQuoteInsurersAddOnsList
      .addCase(fetchNewQuoteInsurersAddOnsList.fulfilled, (state, action) => {
        state.insurerList = action?.payload?.data?.data?.insurerList;
        state.addOnsList = action?.payload?.data?.data?.addOnsList;
        state.isLoading = false;
      })
      .addCase(fetchNewQuoteInsurersAddOnsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNewQuoteInsurersAddOnsList.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setShowNewQuoteRequestMenu,
  setShowRequestNewQuoteModal,
  setShowPolicyMappingModal,
} = newQuoteRequestSlice.actions;

export default newQuoteRequestSlice.reducer;
