import React, { useEffect, useState } from "react";
import { newQuoteRequestHeader } from "../../../Constants/Constants";
import "./FavoritesPage.scss";
import PolicyOfferCard from "../../../Components/PolicyOfferCard/PolicyOfferCard";
import {
  fetchFavoritePolicyDeals,
  updateFavoritePolicyDeals,
} from "../Store/store";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../axios/services";
import Notes from "../../../Components/Notes/Notes";
import {
  JARVIS_BASE_URL,
  MARK_FAVORITE_POLICY_DEAL,
  UNMARK_FAVORITE_POLICY_DEAL,
} from "../../../axios/apiendPoint";
import DealsListing from "../DealsListing/DealsListing";
import { Loader } from "react-feather";

const FavoritesPage = () => {
  const [activeHeader, setActiveHeader] = useState(newQuoteRequestHeader[0]);
  const dispatch = useDispatch();
  const { isLoading, axiosJarvisApi, axiosCustomAll } = useAxiosPrivate();
  const favoriteDeals = useSelector(
    (state) => state.gridReducer.favoritePolicyDeals,
  );
  const notesFavourties = useSelector((state) => state.gridReducer.notes);

  const handleChangeFavourite = async (deal, index) => {
    if (!deal.favouriteId) {
      let response = await axiosCustomAll({
        baseUrl: JARVIS_BASE_URL,
        url: MARK_FAVORITE_POLICY_DEAL,
        method: "POST",
        data: {
          dealId: deal.dealId,
          product: deal.product,
          searchQuery: deal.searchQuery,
        },
      });
      if (response && response.data) {
        dispatch(
          updateFavoritePolicyDeals({
            index,
            policyDetail: {
              ...deal,
              favouriteId: response.data.favouriteId,
            },
          }),
        );
      }
    } else {
      let response = await axiosCustomAll({
        baseUrl: JARVIS_BASE_URL,
        url: UNMARK_FAVORITE_POLICY_DEAL,
        method: "POST",
        data: {
          favouriteId: deal.favouriteId,
        },
      });
      if (response) {
        dispatch(
          updateFavoritePolicyDeals({
            index,
            policyDetail: {
              ...deal,
              favouriteId: null,
            },
          }),
        );

        return;
      }
    }
  };

  useEffect(() => {
    dispatch(
      fetchFavoritePolicyDeals({
        axiosGet: axiosJarvisApi,
        params: `product=${activeHeader?.key}`,
      }),
    );
  }, [activeHeader]);

  return (
    <div className="grid-component-container favorites-page d-flex flex-column align-items-center justify-content-center gap-4">
      <div className="modal_header">
        {newQuoteRequestHeader?.map((item, index) => (
          <div
            key={index}
            className={`d-flex align-items-center justify-content-center flex-column header_items cursor_pointer
                    ${activeHeader?.id === index ? "active_header_item" : ""}`}
            onClick={() => setActiveHeader(item)}
          >
            {/* <img src={item.image} alt={item.id} height={"12px"} width={"20px"} /> */}
            <item.image />
            <div
              className={
                activeHeader?.id === index
                  ? "active_veichle_name"
                  : "veichle_name"
              }
            >
              {item.name}
            </div>
          </div>
        ))}
      </div>
      {isLoading ? (
        <div className="loader">
          <Loader />
          <h3>Loading...</h3>
        </div>
      ) : favoriteDeals && favoriteDeals.length === 0 ? (
        <div className="no-favourites">
          <h3>No Favourites Found</h3>
        </div>
      ) : (
        <DealsListing>
          {favoriteDeals.map((deal, index) => (
            <div className="deals-list-item">
              <PolicyOfferCard
                key={`policy-deal-${index}`}
                index={index}
                policyDetails={deal}
                dealType={deal.dealType}
                handleChangeFavourite={handleChangeFavourite}
              />
            </div>
          ))}
        </DealsListing>
      )}

      <Notes notes={notesFavourties} />
    </div>
  );
};

export default FavoritesPage;
