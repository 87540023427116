import React, { Fragment } from "react";
import CustomAccordion from "../../../../Components/Accordian/Accordian";
import { useSelector } from "react-redux";

const RequestedDetails = () => {
  const details = useSelector(
    (state) => state.quoteRequestReducer.partnerQuoteMainModalDetails,
  );
  const {
    customerDetails = [],
    vehicleDetails = [],
    pypDetails = [],
    quoteRequestDetails = [],
  } = details || {};

  return (
    <Fragment>
      <p className="component_section_name mb_12">Requested Details</p>
      <CustomAccordion data={customerDetails} headerName="Customer Details" />
      <CustomAccordion data={vehicleDetails} headerName="vehicle Details" />
      <CustomAccordion data={pypDetails} headerName="PYP Details" />
      <CustomAccordion
        data={quoteRequestDetails}
        headerName="Request Details"
      />
    </Fragment>
  );
};

export default RequestedDetails;
