import React, { Fragment } from "react";
import "./index.scss";
import CustomModal from "../CustomModal/CustomModal";
import Searching from "../../../assets/lottie/searching.json";
import { Info } from "react-feather";
import CheckCircle from "../../../assets/images/common/check_circle.svg";
import { useSelector } from "react-redux";
import { productsPendingDocumentObj } from "../../../Constants/Constants";
import LottiePlayer from "../../LottiePlayer/LottiePlayer";

const VerificationPendingModal = (props) => {
  const { isOpen, toggle } = props || {};

  const modalDetails = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data;

  const { verifiedPartnerDocument = [], documentUnderVerification = [] } =
    modalDetails || {};

  return (
    <div className="">
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        width="480px"
        modalbody={
          <Fragment>
            <div className="d-flex flex-column align-items-center justify-content-center mb-3">
              <LottiePlayer animationData={Searching} height={126} width={126} />
              <p className="m-0 p-0 mb-3 verified_text text-center">
                Verification Pending
              </p>
              <p className="m-0 p-0 mb-3 verification_pending_description text-center">
                We are verifying your documents and details. Your verification
                will be completed within 24 hours
              </p>
            </div>

            <div className="verification_pending_modal_container card pt-2 pb-0 px-3">
              {verifiedPartnerDocument?.map((item, index) => (
                <div
                  className="d-flex align-items-center justify-content-between my-2"
                  key={index}
                >
                  {/* <p className="pending_verification_doc_name">{productsPendingDocumentObj[item]}</p> */}
                  <p className="pending_verification_doc_name">{item}</p>
                  <p className="pending_verification_doc_status_verified pending_verification_badge">
                    <img src={CheckCircle} alt="check" height="15px" /> Verified
                  </p>
                </div>
              ))}

              {documentUnderVerification?.map((item, index) => (
                <div
                  className="d-flex align-items-center justify-content-between my-2"
                  key={index}
                >
                  {/* <p className="pending_verification_doc_name">{productsPendingDocumentObj[item]}</p> */}
                  <p className="pending_verification_doc_name">{item}</p>
                  <p className="pending_verification_doc_status_verifying pending_verification_badge">
                    <Info size={15} /> Verifying
                  </p>
                </div>
              ))}
            </div>
          </Fragment>
        }
      />
    </div>
  );
};

export default VerificationPendingModal;
