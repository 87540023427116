import React from "react";
import "./index.scss";

const BlurOverlay = ({
  isBlur = true,
  blurClass = "",
  onOuterClick = () => {},
  ...props
}) => {
  // using reducer to handle blur overlay

  const { position, top, left, width, height, minHeight } = props || {};
  return (
    <div
      className={`blur_overlay ${isBlur ? "" : "hidden"} ${blurClass} `}
      style={{
        position: position || "fixed",
        top: top || "0",
        left: left || "0",
        width: width || "100%",
        height: height || "100%",
        minHeight: minHeight || "100%",
      }}
      onClick={() => onOuterClick()}
    ></div>
  );
};

export default BlurOverlay;
