import React, { Fragment } from "react";
import CustomModal from "../../Components/Modal/CustomModal/CustomModal";
import "./index.scss";
import { ReactComponent as Car } from "../../assets/images/Booking/Car.svg";
import { ReactComponent as PolicyImg } from "../../assets/images/Booking/policy.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/common/download_file.svg";

import { X } from "react-feather";
const BookingDataModal = ({ isOpen, data, handleClose }) => {
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={handleClose}
      // modaltitle={}
      width="1000px"
      noModalPadding={true}
      nopadding
      modalbody={
        <Fragment>
          <div className="d_flex  flex_col">
            <div className="d_flex flex_row booking_header">
              <div></div>
              <div className="d_flex flex_col align_center">
                <div style={{ fontSize: "18px", fontWeight: "600" }}>
                  {data.headerData.registrationNumber}
                </div>
                <div style={{ fontSize: "12px", fontWeight: "400" }}>
                  {data.headerData.makeModel}
                </div>
              </div>
              <div className="extra_toggle" onClick={handleClose}>
                <X size={20} />
              </div>
            </div>
            <div className="px_40 pb_24 d_flex gap_24 flex_col">
              <div className="d_flex gap_24 flex_col">
                <div style={{ fontWeight: "600" }} className="booking_heading">
                  <Car
                    style={{ color: "#0691FC" }}
                    color="#0691FC"
                    width={24}
                    height={18}
                  />
                  Vehicle Details
                </div>
                <div className="d_flex flex_row flex_wrap gap_24 grey_95 container">
                  {data.vehicleDetails.map(
                    (item, index) =>
                      item.value && (
                        <div key={index}>
                          <div className="v_key">{item.key}</div>
                          <div className="v_value">{item.value}</div>
                        </div>
                      ),
                  )}
                </div>
              </div>
              <div className="d_flex gap_24 flex_col">
                <div className="d_flex flex_row space_between ">
                  <di className="d_flex flex_row align_center">
                    <div
                      style={{ fontWeight: "600" }}
                      className="booking_heading"
                    >
                      <PolicyImg
                        style={{ color: "#0691FC" }}
                        color="#0691FC"
                        width={24}
                        height={24}
                      />
                      Policy Details
                    </div>
                    <div className="business_type">{data.businessType}</div>
                  </di>
                  <div className="d_flex flex_row gap_8 align_center">
                    <DownloadIcon width={24} height={24} />
                    <a href={data.policyCopyUrl} target="_blank">
                      Download PDF
                    </a>
                  </div>
                </div>
                <div className="policy_details">
                  <div className="d_flex flex_row flex_wrap grey_95 container">
                    {data.policyDetails.map(
                      (item, index) =>
                        item.value && (
                          <div key={index}>
                            <div className="v_key">{item.key}</div>
                            <div className="v_value">{item.value}</div>
                          </div>
                        ),
                    )}
                  </div>
                  <div className="premium d_flex flex_row ">
                    <div style={{ color: "#005814", fontWeight: "500" }}>
                      Premium breakup:
                    </div>
                    <div style={{ fontWeight: "500" }}>
                      {" "}
                      OD - {data.premiumDetails.odPremium}
                    </div>
                    <div style={{ fontWeight: "500" }}>
                      TP - {data.premiumDetails.tpPremium}
                    </div>
                    <div style={{ fontWeight: "500" }}>
                      NET - {data.premiumDetails.netPremium}
                    </div>
                    <div style={{ fontWeight: "600" }}>
                      APE - {data.premiumDetails.totalPremium}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      }
      // headerarrow={"salkjdflaskdj"}
    />
  );
};

export default BookingDataModal;
