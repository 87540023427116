import React from "react";
import "./index.scss";
import QuoteDynamicHeader from "../../../../../Components/QuoteDynamicHeader/QuoteDynamicHeader";
import Clock from "../../../../../assets/lottie/clock.json";
import { Check } from "react-feather";

const PaymentValidating = () => {
  return (
    <div className="mt_12 payment_container">
      <div className="d-flex align-items-center justify-content-between mb_12">
        <p className="component_section_name">Payment Details</p>
        <div className="pending_badge color_danger">
          <Check size={15} /> Validation Pending
        </div>
      </div>
      <div className="card_background p_40 background_dark_blue">
        <QuoteDynamicHeader
          title="We are validating your payment"
          subTitle="We will update the payment status within 1 hour"
          titleClass="payment_validating_title"
          subTitleClass="payment_validating_details"
          noCard
          customImage
          lottieImage={Clock}
        />
      </div>
    </div>
  );
};

export default PaymentValidating;
