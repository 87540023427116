// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_DASHBOARD_DATA } from "../../../axios/apiendPoint";

const initialState = {
  isDocumentVerified: false,
  dashboardData: {},
  isLoading: false,
};

export const fetchDashboardData = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async (thunkparam) => {
    const { axiosGet, params } = thunkparam;
    const response = await axiosGet(GET_DASHBOARD_DATA, params);
    return { data: response?.data };
  },
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setCurrentModal: (state, action) => {
      state.currentModal = action.payload;
    },
    setIsDocumentVerified: (state) => {
      state.isDocumentVerified = !state.isDocumentVerified;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardData.fulfilled, (state, action) => {
      state.dashboardData = action?.payload.data;
      state.isLoading = false;
    });
    builder.addCase(fetchDashboardData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDashboardData.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setCurrentModal, setIsDocumentVerified } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
