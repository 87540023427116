import React, { useEffect, useState } from "react";
import Help from "../../../assets/images/navbar/help.svg";
import "./index.scss";
import { Modal, ModalBody } from "reactstrap";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import Icon from "../../../assets/images/navbar/rigthside.svg";
import cross from "../../../assets/images/navbar/close-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { FetchGlossaryData } from "./store/store";
import useAxiosPrivate from "../../../axios/services";

const VerticalTabs = () => {
  const { axiosJarvisApi } = useAxiosPrivate();
  const [activeTab, setActiveTab] = useState(null);
  const { glossary, isLoading } = useSelector((state) => state.glossaryReducer);

  const [activeTabContent, setActiveTabContent] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchGlossaryData({ axiosGet: axiosJarvisApi }));
  }, [dispatch]);

  useEffect(() => {
    if (glossary.length > 0) {
      setActiveTab(glossary[0].key); // Set the first tab as active by default
    }
  }, [glossary]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    const content = glossary.find((item) => item.key === activeTab);
    setActiveTabContent(content);
  }, [activeTab, glossary]);

  return (
    <div className="glossary-main-tab d-flex">
      <div className="glossary-tab-naving">
        <Nav pills vertical className="flex-column">
          {glossary.map((item) => (
            <NavItem key={item.key} className="glossary-Tabs">
              <NavLink
                className={classnames("glosary-tab-btn", {
                  active: activeTab === item.key,
                })}
                onClick={() => toggleTab(item.key)}
              >
                <span className="icon-tabbing">
                  <img src={Icon} alt="right" />
                </span>
                {item.key}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <TabContent activeTab={activeTab}>
        {activeTabContent && (
          <TabPane tabId={activeTab}>
            <Row>
              <Col sm="12">
                {activeTabContent.value.map((val, idx) => (
                  <ul>
                    <li className="tab-content-set" key={idx}>
                      {val}
                    </li>
                  </ul>
                ))}
              </Col>
            </Row>
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

// Define the HelpingModel component
const HelpingModel = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="grid-main-model ">
      <ModalBody className="model-body-grid">
        <span
          className="cross-icon"
          style={{ cursor: "pointer" }}
          onClick={toggle}
        >
          <img src={cross} alt="cross" />
        </span>
        <VerticalTabs />
      </ModalBody>
    </Modal>
  );
};

// Define the Information component
const Information = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <div>
      <img
        src={Help}
        alt="help"
        onClick={toggle}
        className="navbar_items_border me-2"
        style={{ cursor: "pointer" }}
      />
      <HelpingModel isOpen={modal} toggle={toggle} />
    </div>
  );
};

export default Information;
