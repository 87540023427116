import React, { useState } from "react";
import "./Accordian.scss";
import { ChevronDown, ChevronUp, MinusCircle, PlusCircle } from "react-feather";
import Car from "../../assets/images/QuoteRequest/Car.svg";

const CustomAccordion = ({ data, headerName }) => {
  const [active, setActive] = useState(null);

  const handleToToggle = () => setActive(!active);

  return (
    <div className="custom_accordion_container">
      <div
        className={`single_accordian d-flex justify-content-between`}
        onClick={() => handleToToggle()}
      >
        <div className="header_content">
          <div className="d-flex align-items-center gap-2">
            <img src={Car} alt="car" height={12} width={12} />
            <span className="active_header">{headerName}</span>
          </div>
        </div>
        <div className="">
          {active ? <ChevronUp size={15} /> : <ChevronDown size={15} />}
        </div>
      </div>
      {active && (
        <div className="accordion_content mt_12">
          {data?.map(
            (item, index) =>
              item.value && (
                <div className="mb_16" key={index}>
                  <div className="accordion_title">{item.key}</div>
                  <div className="accordion_value">{item.value}</div>
                </div>
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default CustomAccordion;
