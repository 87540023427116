/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import CustomModal from "../../../../Components/Modal/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleReadTheoryModal,
  setTheoryReadStatus,
  toggleRegulationModal,
} from "./trainingModalStore/store";
import Read from "../../../../assets/images/training/read.png";
import { Info } from "react-feather";
import CustomButton from "../../../../Components/Button/CustomButton/CustomButton";
import useAxiosPrivate from "../../../../axios/services";
import { POST_READ_THEORY } from "../../../../axios/apiendPoint";
import { toast } from "react-hot-toast";
import { fetchdashboardModalStates } from "../../ModalSection/modalSectionStore/store";

const ReadTheoryModal = () => {
  const dispatch = useDispatch();
  const { axiosPost, axiosGet } = useAxiosPrivate();

  const page = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data?.page;
  const trainingModalDetails = useSelector(
    (state) => state.trainingModalReducer,
  );
  const { isReadTheoryOpen, theoryReadStatus } = trainingModalDetails || {};

  const toggle = () => dispatch(toggleReadTheoryModal());

  const handleToReadTheory = async () => {
    const response = await axiosPost(POST_READ_THEORY);
    if (response?.success) {
      toast.success(response?.msg);
      if (response?.courseStudyUrl) {
        window.open(response.courseStudyUrl, "_blank");
      }
      dispatch(fetchdashboardModalStates({ axiosGet }));
      toggle();
    }
  };

  const handleToStart = () => {
    toggle();
    dispatch(toggleRegulationModal());
  };

  useEffect(() => {
    if (page === "GI_EXAM_PENDING_PAGE" || page === "LI_EXAM_PENDING_PAGE")
      dispatch(setTheoryReadStatus(false));
  }, [page]);

  return (
    <Fragment>
      <CustomModal
        isOpen={isReadTheoryOpen}
        toggle={toggle}
        width="649px"
        modaltitle={`Mandatory Certification Exam for ${page === "LI_EXAM_PENDING_PAGE" ? "Life" : "General"} Insurance`}
        modalbody={
          <Fragment>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img src={Read} alt="read" height="119px" />

              <ul className="d-flex gap-5 read_theory_keys">
                <li className="list-item">20 Questions</li>
                <li className="list-item">Multiple Choice Qusetions</li>
                <li className="list-item">45 sec for each questions</li>
              </ul>

              <p className="complete_theory_statement">
                <Info size={16} /> First complete theory and then take exam
              </p>
            </div>

            <hr className="footer_line" />
            <div className="d-flex justify-content-between align-items-center">
              <CustomButton
                buttonName="Read Theory"
                handleClick={handleToReadTheory}
                alignCenter
              />
              <CustomButton
                buttonName="Start Now"
                handleClick={handleToStart}
                disabled={theoryReadStatus}
                alignCenter
              />
            </div>
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default ReadTheoryModal;
