import React, { Fragment, useState, useEffect } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createPolicyMappingRequestPayload,
  newQuoteRequestHeader,
  uploadFileAllowedFormats,
} from "../../Constants/Constants";
import MappingRequestForm from "./MappingRequestForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../axios/services";
import { toast } from "react-hot-toast";
import { CREATE_POLICY_MAPPING_REQUEST } from "../../axios/apiendPoint";
import VerifiedModal from "../../Components/Modal/VerifiedModal/VerifiedModal";
import CustomButton from "../../Components/Button/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { navRoutes } from "../../Router/Url";
import BlurOverlay from "../../Components/BlurOverlay/BlurOverlay";
import { setShowPolicyMappingModal } from "../../Pages/RequestNewQuote/store/store";
import { fetchMappingRequestData } from "../../Pages/MappingRequest/store/store";
import Loader from "../Loader/Loader";

const CreatePolicyMappingRequest = () => {
  const dispatch = useDispatch();
  const { axiosGet2, axiosFormDataPost2 } = useAxiosPrivate();
  const navigate = useNavigate();
  const [activeHeader, setActiveHeader] = useState({});
  const [veichleState, setVeichleState] = useState(["Vehicle is New"]);
  const [veichleType, setVeichleType] = useState(["Comprehensive Policy"]);
  const [previousPolicyFlag, setPreviousPolicyFlag] = useState(["No"]);
  const [claimPolicyFlag, setClaimPolicyFlag] = useState(["No"]);

  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [currentAddOn, setCurrentAddOn] = useState([]);

  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const newVeichleSchema = yup.object().shape({
    contactNumber: yup
      .string()
      .nullable()
      .matches(/^\d{10}$/, "Contact Number must be exactly 10 digits")
      .transform((value, originalValue) => (originalValue === "" ? null : value)),
    policyNumber: yup.string().required("Policy number is required"),
    registrationNumber:
      veichleState[0] !== "Vehicle is New"
        ? yup.string().required("Registration number is required")
        : yup.string().nullable(),
    rtoCode:
      veichleState[0] === "Vehicle is New"
        ? yup.string().required("RTO code is required")
        : yup.string().nullable(),
    vehicleOwner:
      activeHeader?.key === "BUS"
        ? yup.string().required("Vehicle owner name is required")
        : yup.string().nullable(),
    vehicleSubClass:
      activeHeader?.key === "BUS"
        ? yup.string().required("Vehicle subtype is required")
        : yup.string().nullable(),
    POLICY_COPY: yup
      .mixed()
      .required("Please select a file")
      .test("fileType", "Invalid file format", (value) => {
        if (!value) return false;
        const supportedFormats = uploadFileAllowedFormats;
        return supportedFormats.includes(value.type);
      })
      .test("fileSize", "The file is too large", (value) => {
        return value && value.size <= 5000000; // 5MB maximum file size
      }),
    PERMIT:
      activeHeader?.key === "BUS"
        ? yup
          .mixed()
          .required("Please select a file")
          .test("fileType", "Invalid file format", (value) => {
            if (!value) return false;
            const supportedFormats = uploadFileAllowedFormats;
            return supportedFormats.includes(value.type);
          })
          .test("fileSize", "The file is too large", (value) => {
            return value && value.size <= 5000000; // 5MB maximum file size
          })
        : yup.string().nullable(),
  });

  const {
    control: controlNew,
    handleSubmit: handleSubmitNew,
    formState: { errors: errorsNew },
    reset: resetNew,
    resetField: resetFieldNew,
    setValue: setValueNew,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(newVeichleSchema),
    defaultValues: {
      vehicleSubClass: null,
      vehicleOwner: "",
      registrationNumber: null,
      rtoCode: "",
      policyNumber: "",
      POLICY_COPY: null,
      PERMIT: null,
      OTHERS: null,
      contactNumber: null,
    },
  });

  const handleToReset = () => {
    resetNew();
    setActiveHeader(newQuoteRequestHeader[0]);
    setVeichleState(["Vehicle is New"]);
    setVeichleType(["Comprehensive Policy"]);
    setPreviousPolicyFlag(["No"]);
    setClaimPolicyFlag(["No"]);
    setSelectedAddOns([]);
  };

  const handleResetField = (field, value) => {
    resetFieldNew(field);
  };

  const handleSetValues = (field, value) => {
    setValueNew(field, value);
  };
  const getMappingRequestList = () =>
    dispatch(fetchMappingRequestData({ axiosGet: axiosGet2 }));

  const handleToSubmit = async (data) => {
    setIsLoader(true);

    const payload = {
      ...data,
      product: activeHeader?.key,
      vehicleType: veichleState[0] === "Vehicle is New" ? "NEW" : "OLD",
    };
    const formData = createPolicyMappingRequestPayload(payload);

    const response = await axiosFormDataPost2(
      CREATE_POLICY_MAPPING_REQUEST,
      formData,
    );
    if (response?.success) {
      toast.success(response?.msg);
      setIsFormSubmited(true);
      setIsLoader(false);
      handleToReset();
      getMappingRequestList();
    } else {
      setIsLoader(false);
    }
  };

  const handleToNavigateToQuoteList = () => {
    setIsFormSubmited(false);
    dispatch(setShowPolicyMappingModal());
    navigate(navRoutes.mappingRequestPage);
  };

  useEffect(() => {
    setActiveHeader(newQuoteRequestHeader[0]);
  }, []);

  return (
    <>
      <div
        className={
          isFormSubmited
            ? "requestMappingModalContainer submittedModal"
            : "requestMappingModalContainer"
        }
      >
        {isLoader ? (
          <Loader />
        ) : isFormSubmited ? (
          <div>
            <VerifiedModal verifiedText="Mapping Request Raised Successfully!" />

            {/* <div>Ticket Id :- {ticked.id} </div> */}
            <div className="d-flex align-items-center justify-content-center">
              <CustomButton
                buttonName="Go To My Requests"
                handleClick={handleToNavigateToQuoteList}
                alignCenter
              />
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="modal_header">
              {newQuoteRequestHeader?.map((item, index) => (
                <div
                  key={index}
                  className={`d-flex align-items-center justify-content-center flex-column header_items cursor_pointer
                    ${activeHeader?.id === index ? "active_header_item" : ""}`}
                  onClick={() => setActiveHeader(item)}
                >
                  {/* <img src={item.image} alt={item.id} height={"12px"} width={"20px"} /> */}
                  <item.image />
                  <div
                    className={
                      activeHeader?.id === index
                        ? "active_veichle_name"
                        : "veichle_name"
                    }
                  >
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
            <MappingRequestForm
              veichleState={veichleState}
              setVeichleState={setVeichleState}
              handleSubmit={handleSubmitNew}
              handleToSubmit={handleToSubmit}
              errors={errorsNew}
              control={controlNew}
              reset={handleToReset}
              resetField={handleSetValues}
              selectedAddOns={selectedAddOns}
              setSelectedAddOns={setSelectedAddOns}
              setVeichleType={setVeichleType}
              veichleType={veichleType}
              previousPolicyFlag={previousPolicyFlag}
              setPreviousPolicyFlag={setPreviousPolicyFlag}
              claimPolicyFlag={claimPolicyFlag}
              setClaimPolicyFlag={setClaimPolicyFlag}
              currentAddOn={currentAddOn}
              setCurrentAddOn={setCurrentAddOn}
              activeHeader={activeHeader}
            />
          </Fragment>
        )}
      </div>
      <BlurOverlay
        isBlur={true}
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
      />
    </>
  );
};

export default CreatePolicyMappingRequest;
