import Bookings from "../Pages/Bookings";
import Dashboard from "../Pages/Dashboard/Dashboard";
import KnowledgeBank from "../Pages/KnowledgeBank";
import MappingRequest from "../Pages/MappingRequest";
import QuoteRequest from "../Pages/QuoteRequest";
import Tickets from "../Pages/Tickets";
import { navRoutes } from "./Url";
import GridPage from "../Pages/Grid";
import FavoritesPage from "../Pages/Grid/FavoritesPage/FavoritesPage";

// ** Merge Routes
export const AllRoutes = [
  {
    path: navRoutes.defaultPage,
    element: <Dashboard />,
  },
  {
    path: navRoutes.dashboardPage,
    element: <Dashboard />,
  },
  {
    path: navRoutes.quoteRequestPage,
    element: <QuoteRequest />,
    isRegisteredRequired: true,
  },
  {
    path: navRoutes.bookingsPage,
    element: <Bookings />,
    isRegisteredRequired: true,
  },
  {
    path: navRoutes.knowledgeBankPage,
    element: <KnowledgeBank />,
    isRegisteredRequired: false,
  },
  {
    path: navRoutes.ticketsPage,
    element: <Tickets />,
    isRegisteredRequired: true,
  },
  {
    path: navRoutes.mappingRequestPage,
    element: <MappingRequest />,
    isRegisteredRequired: true,
  },
  {
    path: navRoutes.grid,
    element: <GridPage />,
    isRegisteredRequired: true,
  },
  {
    path: navRoutes.gridFavorites,
    element: <FavoritesPage />,
    isRegisteredRequired: true,
  },
];
