import React from "react";
import VerifiedModal from "../../../../../Components/Modal/VerifiedModal/VerifiedModal";
import Icici from "../../../../../assets/images/Removed/icici.svg";

const QuoteAccepted = () => {
  return (
    <>
      <p className="component_section_name mb_12">Shared Quotes</p>
      <div className="card_background p_40">
        <VerifiedModal
          verifiedText="Quote Accepted Successfully!"
          verifiedDescription={
            <div className="d-flex align-items-center jsutify-content-center flex-column gap-4">
              <img src={Icici} alt="logo" className="logo_image" />

              <p>We will update you shortly once we get approval from ICICI</p>
            </div>
          }
        />
      </div>
    </>
  );
};

export default QuoteAccepted;
